<template>
  <div class="loading">
    <img src="@/assets/img/loading.svg" />
  </div>
</template>

<script>
export default {
  name: "page-not-found",
  methods: {
    login(user_id) {
      let data = {
        user_id: user_id,
      };

      this.$store.dispatch("authRequestWhitUser", data);
    },
  },
  created() {
    let user_id = atob(this.$route.params.userid);
    this.login(user_id);
  },
};
</script>

<style lang="scss">
.loading {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;

  img {
    width: 100px;
  }
}
</style>
